<app-nav-bar></app-nav-bar>

<!-- Start Section -->
<section class="section pb-40">
    <div class="container">
        <div class="col-md-12 row dashboardmedia">
            <div class="col-lg-3" id="card" *ngFor="let item of Menus">
                <div style="cursor: pointer" (click)="gotorespectivepage(item)">
                    <span class="center" style="cursor: pointer">
            <img
              class="center"
              [src]="item.image"
              style="width: 50px; height: 50px; cursor: pointer"
              (click)="gotorespectivepage(item)"
            />
          </span>
                    <div>&nbsp;</div>
                    <h4 style="text-align: center">{{ item.menuName }}</h4>
                    <p style="text-align: center">
                        {{ item.menuFileName }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--For external application -->
    <!-- <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <a href="https://blocdrive.com/CustomerManager/#/" style="text-decoration: none">
            <div class="text-center" style="cursor: pointer">
              <img
                src="https://accountdatastorage.blob.core.windows.net/pictures/task-photo-08354019-035a-48d1-80e6-9d6ad3e76a7a.png"
                alt="CRM"
                style="width: 50px; height: 50px"
              />
              <h4 class="mt-3">CRM</h4>
              <p>Customer Relationship Manager</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>  -->
  <section class="section pb-40" *ngIf="userrole === '1'">
    <div class="container">
      <div class="col-md-12 row dashboardmedia">
        <div class="col-lg-3" id="card" (click)="gotoCrmPage()" style="cursor: pointer">
          <img
            class="center pointer"
            [src]="'https://accountdatastorage.blob.core.windows.net/pictures/task-photo-08354019-035a-48d1-80e6-9d6ad3e76a7a.png'"
            alt="CRM Icon"
            style="width: 50px; height: 50px;"
          />
          <div>&nbsp;</div>
          <h4 class="text-center">CRM</h4>
          <p class="text-center">Customer Relationship Manager</p>
        </div>
      </div>
    </div>
  </section>

  <br />
<br /><br /><br />
<h3>Smart Contract Transactions</h3>

<table class="table table-primary">
    <thead class="thead-dark">
        <tr> 
            <th>User</th>
            <th>Mail</th>
            <th>GUID</th>
            <th>Transaction Type</th>
            <th>Transaction Date</th>
          
        </tr>
    </thead>
    <tbody>
        <tr  *ngFor="let aa of dataa">
            <!-- <td>{{5*(p-1)+(i+1)}}</td>  -->
            <td>{{aa.fromuser}}</td>
            <td>{{aa.touser}}</td>
            <th>{{aa.fromuserguid}}</th>
          <th>{{aa.transaction}}</th>
          <th>{{aa.touserguid}}</th>        
        </tr>
    </tbody>
</table>

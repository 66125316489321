import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smartcontractlist',
  templateUrl: './smartcontractlist.component.html',
  styleUrls: ['./smartcontractlist.component.css']
})
export class SmartcontractlistComponent implements OnInit {

  inbox: any=[];
dataa:any=[];
  constructor(private httpservice:HttpClient) { }

  ngOnInit(): void {
    this.transactions();
  }
  transactions() {
    this.httpservice.get<any[]>('http://192.168.0.230:8080/api/getallusersbd').subscribe(
      (data: any[]) => {
        this.dataa = data; // Store the list in `this.dataa`
      },
      (error) => {
        console.error('Error fetching data:', error); // Handle errors
      }
    );
  }
  // transactions() {
  //   this.httpservice.get<any>('http://192.168.0.230:8080/api/getallusersbd').subscribe(
  //     (data: any) => {
  //       // If data.response is a JSON string
  //       this.dataa = JSON.parse(data.response);
  
  //       // If data.response is already an array, no need to parse
  //       // this.dataa = data.response; 
  //     },
  //     (error) => {
  //       console.error('Error fetching data:', error);
  //     }
  //   );
  // }
}
